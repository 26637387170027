module.exports = [{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1889914156","enableOnDevMode":true},
    },{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://cbb258c69ce94da294cf904f41be8381@o16568.ingest.sentry.io/5781030","environment":"production","enabled":true},
    },{
      plugin: require('/srv/thanx-signup-ui/node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"st-"},
    },{
      plugin: require('/srv/thanx-signup-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
